import React from 'react';
import '../App.css';
import GoogleStore from '../assets/googbadge.svg'
import AppleStore from '../assets/appbadge.svg'
import RegisterScreen from '../assets/registerscreen.png'

class HowItWorks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {howText:"Gridspot's mobile application is very simple to use.  Upon becoming a member, you gain access to our network of public EV chargers, similar to platforms like ChargePoint. Browse as a guest, become a member, or host your own charger!"}
  }

  componentDidMount() {
   //
  }


  componentWillUnmount() {
  }
  render() {
    return (


   this.props.mobile ? 
    <div style={{marginTop:'60px'}}>
      <div style={{textAlign:'left',paddingBottom:'30px'}}>
         <span style={{fontSize:'30px',fontWeight:'600'}}> How Does It Work? </span> <br/> <br/>
         <span style={{color: '#686868',fontSize: '20px',fontWeight:'400',lineHeight:'30.7px'}}>
	    {this.state.howText}
          </span>  
      </div>
      <center> 
       <div style={{display:'block'}}>
         <div style={{width:'100%',padding: '24px',gap:'46px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#1E2426'}}>
            <div> 
              <span style={{color:'white',fontSize:'20pt'}}> Download App </span> <br/> <br/>
              <span style={{color:'#A6A6A6',fontSize:'14pt'}}> Download Gridspot App From Play Store or App Store </span>   
            </div> <center>
             <div style={{marginTop:'60px'}}> <a href="https://play.google.com/store/apps/details?id=co.gridspot.app&pli=1"> <img src={GoogleStore} style={{objectFit:'contain',cursor:'pointer',height:'80%',width:'80%'}}/> </a> </div> 
            <div> <a href="https://apps.apple.com/us/app/gridspot/id6476886226"> <img src={AppleStore} style={{objectFit:'contain',height:'80%',width:'80%'}}/> </a> </div> </center>
         </div> <br/>
         <div style={{width: '100%',borderRadius: '24px',border: '2px solid #EBEBEB',background:'linear-gradient(#f2f1fd, #ffffff 50%)',overflowY:'hidden'}}>
            <div style={{padding:'24px',fontSize:'20pt'}}>  Register or Login  </div>
            <div style={{color:'#8c8c8c',paddingLeft:'24px',fontSize:'14pt',paddingRight:'24px'}}>  Register or Login to the Gridspot Application <br/><br/> </div>
            <div style={{}}> <img src={RegisterScreen} style={{objectFit:'contain',height:'100%',width:'100%'}}/> </div>
         </div> <br/>
         <div style={{width: '100%',borderRadius: '24px',border: '2px solid #EBEBEB',background:'linear-gradient(#f2f1fd, #ffffff 50%)'}}>
            <div style={{padding:'24px',fontSize:'16pt',textAlign:'left'}}>  Public or Private  </div>
            <div style={{color:'#8c8c8c',fontSize:'12pt',padding:'12px'}}>  Share or keep your charger private </div>
            <div style={{padding:'24px',fontSize:'12pt',textAlign:'left',color:'black'}}> Join the Gridspot community to provide an opportunity for EV owners to charge their vehicles with you. Earn money while contributing to a sustainable future. </div>
         </div>
      </div>
     </center>
    </div>


:



    <div style={{marginTop:'80px'}}>
      <div style={{textAlign:'left',width:'800px',paddingBottom:'30px'}}>
         <span style={{fontSize:'56px',fontWeight:'600'}}> How Does It Work? </span> <br/> <br/>
         <span style={{color: '#686868',fontSize: '24px',fontWeight:'400',lineHeight:'30.7px'}}>
	    {this.state.howText}
          </span>  
      </div>
      <div style={{display:'flex',flexDirection:'row',height:'550px',overflowY:'hidden'}}>
         <div style={{flex:'10',width: '198px',padding: '24px',gap:'46px',borderRadius:'24px',border: '2px solid #EBEBEB',background:'#1E2426'}}>
            <div> 
              <span style={{color:'white',fontSize:'20pt'}}> Download App </span> <br/> <br/>
              <span style={{color:'#A6A6A6',fontSize:'14pt'}}> Download Gridspot App From Play Store or App Store </span>   
            </div> <center>
            <div style={{marginTop:'60px'}}><a href="https://play.google.com/store/apps/details?id=co.gridspot.app&pli=1"> <img src={GoogleStore} style={{objectFit:'contain',height:'80%',width:'80%'}}/> </a> </div>
            <div> <a href="https://apps.apple.com/us/app/gridspot/id6476886226"> <img src={AppleStore} style={{objectFit:'contain',height:'80%',width:'80%'}}/>  </a> </div> </center>
         </div>
         <div style={{flex:'1'}}> </div>
         <div style={{flex:'10',width: '297px',borderRadius: '24px',border: '2px solid #EBEBEB',background:'linear-gradient(#f2f1fd, #ffffff 50%)',overflowY:'hidden'}}>
            <div style={{padding:'24px',fontSize:'20pt'}}>  Register or Login  </div>
            <div style={{color:'#8c8c8c',paddingLeft:'24px',fontSize:'14pt',paddingRight:'24px'}}>  Register or Login to the Gridspot Application <br/><br/> </div>
            <div style={{}}> <img src={RegisterScreen} style={{objectFit:'contain',height:'100%',width:'100%'}}/> </div>
         </div>
         <div style={{flex:'1'}}> </div>
         <div style={{flex:'20',width: '625px',borderRadius: '24px',border: '2px solid #EBEBEB',background:'linear-gradient(#f2f1fd, #ffffff 50%)'}}>
            <div style={{padding:'24px',fontSize:'20pt'}}>  Public or Private  </div>
            <div style={{color:'#8c8c8c',paddingLeft:'24px',fontSize:'14pt'}}>  Share or keep your charger private </div>
            <div style={{padding:'24px',paddingTop:'56px',fontSize:'16pt'}}> Join the Gridspot community to let your customers or tennants charge their vehicles without bearing the cost of electricity. You can even charge a premium and earn money while contributing to a sustainable future. Alternatively, if you need a charger, our platform allows you to easily rent time on one from the many hosts in the Gridspot network. Embrace the convenience and benefits of our collaborative charging solution today.</div>
         </div>
      </div>
    </div>



    )
  }
}

export default HowItWorks;
