export function redirectToStore() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location = 'https://apps.apple.com/us/app/gridspot/id6476886226' 
    }
    // Android detection
    else if (/android/i.test(userAgent)) {
      window.location = 'https://play.google.com/store/apps/details?id=co.gridspot.app&pli=1'; 
    }
    // If neither iOS nor Android, you might want to redirect to your website or show a message
    else {
      // Handle other devices or show a message
      window.location = 'https://apps.apple.com/us/app/gridspot/id6476886226' 
    }
  }


