import React from 'react';
import AppImages1 from '../assets/appImages1.png';
import Technology from '../assets/technology.png';
import Sharing from '../assets/sharing.png';
import Network from '../assets/network.png';
import Membership from '../assets/membership.png';
import '../App.css';

class WhatWhy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {whatText:"Gridspot is a full service EV Charging Company:  Installing, maintaining and repairing EV chargers. We also allow users to list their own chargers of any kind on our app. You can rent time on your own charger to others in need...or rent chargers from other members of the Gridspot community. This collaborative approach to charging enables EV owners to find convenient and accessible charging options.  It also provides an opportunity for charger owners to earn income by sharing. Gridspot aims to promote the transition to electric vehicles by letting users access the millions of home EV chargers that are currently operational across the world."}

  }

  componentDidMount() {
   //
  }


  componentWillUnmount() {
  }

  render() {
    return (

   this.props.mobile ? 
 
    <div>
      <div style={{display:'block',paddingTop:'0px'}}> 
        <div style={{paddingTop:'40px',textAlign:'left'}}>
           <span style={{fontSize:'30px',fontWeight:'600',textAlign:'left'}}> What Is Gridspot? </span> <br/> <br/><br/><br/>
           <span style={{color: '#686868',fontSize: '20px',fontWeight:'400',lineHeight:'30.7px'}}>
	     {this.state.whatText}
            </span> 
        </div> <br/><br/>
        <div style={{}}>
          <center>  <img src={AppImages1} style={{objectFit:'contain',height:'60%',width:'60%'}}/>  </center>
        </div> <br/><br/><br/>
      </div> 
      <div style={{textAlign:'left',width:'100%',paddingBottom:'30px'}}>
      <span style={{fontSize:'30px',fontWeight:'600'}}> Why Gridspot? </span> <br/> <br/>
       <span style={{color: '#686868',fontSize: '20px',fontWeight:'400',lineHeight:'30.7px'}}>
          Choose Gridspot for seamless access to a widespread network of car chargers via our convenient membership program.
       </span>  
       </div> 
      <div style={{display:'block',justifyContent:'center'}}> 
          <center>
          <div class="whyboxmobile">
           <img src={Membership} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'20pt',fontWeight:'500'}}>  Gridspot Membership </span> <br/>
             <span style={{color:'#696969',fontSize:'16pt'}}>
                Access Gridspot's network of ev chargers across our global network through a convenient phone application
             </span>
          </div><br/>
          <div class="whyboxmobile">
           <img src={Network} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'20pt',fontWeight:'500'}}>  Comprehensive Location Network  </span> <br/>
           <span style={{color:'#696969',fontSize:'16pt'}}>
              Find a nearby charger wherever you are, making electric vehicle ownership and usage effortless and convenient.
           </span>
          </div> <br/>
          <div class="whyboxmobile">
           <img src={Sharing} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'20pt',fontWeight:'500'}}>  Charger Sharing Program </span> <br/>
             <span style={{color:'#696969',fontSize:'16pt'}}>
               Share your charger within Gridspot community, providing an opportunity to earn by allowing others to charge their vehicles on your property.
             </span>
          </div> <br/>
          <div class="whyboxmobile">
           <img src={Technology} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'20pt',fontWeight:'500'}}>  Flexible Businness Model  </span> <br/>
           <span style={{color:'#696969',fontSize:'16pt'}}>
               Customize your charging experience: Public/Private, self managed or Gridspot managed. We will work with you!
	    </span>
          </div><br/>
          </center>
       </div>
     </div>
   
   :


    <div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',maxWidth:'2400px',height:'1000px',paddingTop:'80px'}}>
        <div style={{flex:'1',paddingTop:'150px',textAlign:'left'}}>
           <span style={{fontSize:'58px',fontWeight:'600',textAlign:'left'}}> What Is Gridspot? </span> <br/> <br/><br/><br/>
           <span style={{color: '#686868',fontSize: '27px',fontWeight:'400',lineHeight:'30.7px'}}>
	      {this.state.whatText}
            </span> 
        </div>
        <div style={{flex:'1',justifyContent:'right',textAlign:'right'}}>
           <img src={AppImages1} style={{objectFit:'contain',height:'100%',width:'100%'}}/> 
        </div>
      </div>
      <div style={{textAlign:'left',width:'100%',paddingBottom:'30px',marginTop:'40px'}}>
      <span style={{fontSize:'56px',fontWeight:'600'}}> Why Gridspot? </span> <br/> <br/>
       <span style={{color: '#686868',fontSize: '27px',fontWeight:'400',lineHeight:'30.7px'}}>
          Choose Gridspot for seamless access to a widespread network of car chargers via our convenient membership program.
       </span>  
       </div>
      <div style={{width:'100%',flexWrap:'wrap',display:'flex',justifyContent:'center'}}> 
          <div class="whybox">
           <img src={Membership} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'25pt',fontWeight:'500'}}>  Gridspot Membership </span> <br/>
             <span style={{color:'#696969',fontSize:'20pt'}}>
                Access Gridspot's network of ev chargers across our network through a convenient phone application
             </span>
          </div>
          <div style={{flex:1}}> </div>
          <div class="whybox">
           <img src={Network} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'25pt',fontWeight:'500'}}>  Comprehensive Location Network  </span> <br/>
           <span style={{color:'#696969',fontSize:'20pt'}}>
              Find a nearby charger wherever you are, making electric vehicle ownership and usage effortless and convenient.
           </span>
          </div>
      </div> <br/><br/><br/>
       <div style={{width:'100%',flexWrap:'wrap',display:'flex',justifyContent:'center'}}> 
          <div class="whybox">
           <img src={Sharing} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'25pt',fontWeight:'500'}}>  Charger Sharing Program </span> <br/>
             <span style={{color:'#696969',fontSize:'20pt'}}>
               Share your charger within Gridspot community, providing an opportunity to earn by allowing others to charge their vehicles on your property.
             </span>
          </div>
          <div style={{flex:1}}> </div>
          <div class="whybox">
           <img src={Technology} style={{objectFit:'contain',height:'90%',width:'100%'}}/>
           <span style={{fontSize:'25pt',fontWeight:'500'}}>  Flexible Buisiness Model  </span> <br/>
           <span style={{color:'#696969',fontSize:'20pt'}}>
               Customize your charging experience: Public or Private, Self-Managed or Gridspot managed.  We will work with you!
           </span>
          </div>
      </div>

     </div>
    )
  }
}

export default WhatWhy;
