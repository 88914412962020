import React from 'react';
import '../App.css';
import Plus from '../assets/plus'
import Minus from '../assets/minus'
import QuestionBox from './QuestionBox'

class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
   //
  }


  componentWillUnmount() {
  }

  render() {
    return (
    <div style={{marginTop: this.props.mobile ? '30px' : '80px'}}>
      <div style={{textAlign:'left',paddingBottom:'30px'}}>
         <span style={{fontSize:this.props.mobile ? '30px' : '56px',fontWeight:'600'}}> Any More Questions? </span> <br/> <br/>
         <span style={{color: '#686868',fontSize: this.props.mobile ? '20px' : '24px',fontWeight:'400',lineHeight:'30.7px'}}>
            Let's get started and power up your journey towards a greener future today!
          </span>  
      </div>
      <div style={{display:'flex',flexDirection:'column',justifyContents:'space-between'}}>
       <QuestionBox question="How Does Gridspot Work?" answer="After downloading the app and registering an account you can immediately access the Gridspot map to see the availability of chargers in a desired location. You can reserve your spot for the amount of charging time you need.  If you own a charger on your property you may optionally sign up for a host account to sell time on your charger at the times most convenient for you." mobile={this.props.mobile}/>
        <QuestionBox question="What Are The Prices & Fees?" answer="Charger hosts can set whatever price/hour they want, Gridspot takes $1.50 from the host and $1.50 from the charger user once the charge reservation is confirmed. If you are looking to have EV Charger(s) installed on your property please contact our team using the contact button above!" mobile={this.props.mobile}/>
  <QuestionBox question="What Types of EV Chargers Do You Offer" answer="We currently are offering level 2 J1772 or NACS 7 kWh (240V) chargers and accept all major credit cards." mobile={this.props.mobile}/>
       <QuestionBox question="What are the Benefits of Installing EV Chargers on My Property?" answer="You can benefit by collecting additional revenue from charging, as well as provide your customers/tenants a valuable ammenity that is widely in demand. The IEA predicts that half of all cars sold globally will be electric by 2035!" mobile={this.props.mobile}/>
      </div>
   </div>
    )
  }
}

export default Questions;
