import React from 'react';
import './App.css';
import Header from './components/Header'
import HeaderHow from './components/HeaderHow'
import WhatWhy from './components/WhatWhy'
import HowItWorks from './components/HowItWorks'
import Testimonials from './components/Testimonials'
import LetsStart from './components/LetsStart'
import Questions from './components/Questions'
import Footer from './components/Footer'
import ContactForm from './components/ContactForm'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {mobile:window.innerWidth <= 768,page:'main'};
    this.pageChanged = this.pageChanged.bind(this);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  pageChanged = (page) => {
    this.setState({page:page})
  }

  render() {
    return (
     <div>
       {this.state.page == 'contact' && <ContactForm pageChanged={this.pageChanged}/>}
       
       {['main','how'].indexOf(this.state.page) >= 0 && <div style={{width:'100vw',height:this.props.page == 'main' ? '790px': '1400px'}}>
         {this.state.page == 'how' && <HeaderHow mobile={this.state.mobile} pageChanged={this.pageChanged}/>}
         {this.state.page == 'main' && <Header mobile={this.state.mobile} pageChanged={this.pageChanged}/>} 
       </div> }

       {['main','how'].indexOf(this.state.page) >= 0 && <div style={{paddingLeft:'5%',paddingRight:'5%'}}>
             <WhatWhy mobile={this.state.mobile}/>
             {this.state.page == 'main' && <HowItWorks mobile={this.state.mobile}/>}
             {/*this.state.page == 'main' && <Testimonials mobile={this.state.mobile}/>*/}
             <LetsStart  mobile={this.state.mobile}/>
             <Questions mobile={this.state.mobile} />
       </div>}
             <Footer mobile={this.state.mobile} pageChanged={this.pageChanged}/>
     </div> 
    )
  }
}

export default App;
