import React from 'react';
import GSLogo from '../assets/GSLogo.png'
import Oval0 from '../assets/oval0'
import Oval from '../assets/oval'
import Bolt from '../assets/bolt'
import CarCharge from '../assets/howchg.png'
import '../App.css';
import {redirectToStore} from './Helpers'

class HeaderHow extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    //this.props.mobile ?  
  }
     
   render() {
    return (

     <div id="howcontainer">
       <div id="howunder">
          <div style={{display:'flex',justifyContent:'space-evenly'}}>
             <div style={{flex:1,width:'100px',paddingTop:'100px'}}>
                <Oval0/>      
             </div>     
              <div style={{flex:1,width:'100px',paddingTop:'140px'}}>
                <center>   <Bolt/>     </center> 
             </div>     
              <div style={{flex:1,width:'100px'}}>
                <Oval/>      
             </div>     
          </div> 
       </div>
         <div id="howover">
           <div style={{display:'block',justifyContent:'space-evenly',alignItems:'stretch',height:'1500px',marginTop:'20px'}}>
              <div style={{flex:'1',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                 <div style={{flex:1,justifyContent:'left',textAlign:'left',paddingLeft:'10px'}}>
                   <a href="https://www.gridspot.co"> <img src={GSLogo} width='150px'/> </a>
                 </div> 
                 <div style={{flex:3,marginTop:'4px'}}>
                    <center> 
                       <div class="header-menu" style={{background:'#FFF',boxShadow:'0px 4px 20px 0px rgba(0, 0, 0, 0.05) inset', color:'black',borderRadius:'64px',border:'2px solid rgba(0, 0, 0, 0.10)'}}>
                         <div style={{cursor:'pointer'}} onClick={e=>this.props.pageChanged('main')}>
                           Home
                        </div>
                        <div style={{color:'#23AAE1'}}>
                           <b> How It Works </b>
                        </div>  
                        <div style={{cursor:'pointer'}} onClick={e=>this.props.pageChanged('contact')}>
                          Contact
                        </div> 
                      </div>
                   </center>
                 </div>
                 <div style={{flex:'1',paddingRight:'20px',textAlign:'right'}}>
                    <div class="download-button" onClick={e=>redirectToStore()} style={{backgroundColor:'#23AAE1'}}> Download App </div>
                 </div>      
              </div> 
                   <div style={{textAlign: 'center',fontFamily: 'Helvetica',fontSize: '56px',fontWeight: '400',lineHeight:'130%',marginTop:'70px'}}>
                       How Does <span style={{color:'#23AAE1',fontWeight:'600'}}> Gridspot </span> Work?
                   </div>
                   <center>
                      <div style={{marginTop:'10px',paddingBottom:'10px',textAlign:'center',width:'600px',fontSize:'20px',fontWeight:'400',lineHeight:'30.7px'}}> 
                         Gridspot's mobile application is simple and user-friendly.  You can browse as a guest, or join for free and gain access to our network of car chargers operated by hosts. You can also choose to host your own EV Charger and start earning money!  <br/><br/>
                        <div style={{display:'flex',justifyContents:'center',marginBottom:'60px'}}>
                          <div style={{flex:1}}> </div>
                          <div style={{flex:1}}>
                             <span style={{color:'#FFF',fontSize: '18px',fontWeight:'400',lineHeight:'normal',display:'inline-flex',padding:'9.5px 20px',justifyContent:'center',alignItems:'center',gap:'8px',borderRadius:'40px',border:'2px solid var(--back-ground-content-background-primary, #FFF)',backgroundColor:'#23AAE1',cursor:'pointer'}} onClick={e=>redirectToStore()}>
                               Get Started
                             </span>
                           </div>
                           <div style={{flex:1}}>
                             <span style={{fontSize:'18px',lineHeight:'normal',display:'inline-flex',padding:'9.5px 20px',gap:'8px',color:'#23AAE1'}}> Learn More  ↓ </span> 
                           </div>
                          <div style={{flex:1}}> </div>
                        </div>
                     </div>
                     <div style={{width:'1280px',height:'851px',flexShrink:'0',borderRadius: '10px',backgroundImage : `url(${CarCharge})`,display:'flex',justifyContent:'center',alignItems: 'center',color:'white',fontSize:'40px'}}>
                       <div style={{padding:'200px'}}> 
                          <center> Use our Chargers for Your Car and Stay <b> Connected </b> Wherever the Road Takes You. </center>
                       </div>
                     </div>
                   </center>

             </div> 
         </div>
    </div>        

    )
  }
}

export default HeaderHow;
